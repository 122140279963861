import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SecondaryNavComponent } from './components/secondary-nav/secondary-nav.component';
import { MagicBitSectionComponent } from './components/magic-bit-section/magic-bit-section.component';
import { AlGreetModalContentComponent } from './components/al-greet-modal-content/al-greet-modal-content.component';
import { GlobalSearchComponent } from './components/global-search/global-search.component';
import { FormsModule } from '@angular/forms';
import { MobileSearchFilterComponent } from './popups/mobile-search-filter/mobile-search-filter.component';

import { LottieModule, provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    MainLayoutComponent,
    BreadcrumbComponent,
    SecondaryNavComponent,
    MagicBitSectionComponent,
    AlGreetModalContentComponent,
    GlobalSearchComponent,
    MobileSearchFilterComponent
  ],
  imports: [
    LottieModule.forRoot({ player: playerFactory }),
    CommonModule,
    RouterModule,
    FormsModule
  ],
  exports: [
    MainLayoutComponent,
    BreadcrumbComponent,
    SecondaryNavComponent,
    MagicBitSectionComponent,
    GlobalSearchComponent
  ],
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
  ],
})
export class CoreModule { }
