import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LOCALE_ID, Inject } from '@angular/core';
import { WindowService } from '../../services/window.service';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutConfirmComponent } from 'src/app/login/pages/components/logout-confirm/logout-confirm.component';
import { AlGreetModalContentComponent } from '../../components/al-greet-modal-content/al-greet-modal-content.component';
import { GlobalSearchComponent } from '../../components/global-search/global-search.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
// declare var window: any;

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  animations: [
    trigger('fadeTopHeader', [
      state('visible', style({
        opacity: 1,
        // height: '100%'
      })),
      state('hidden', style({
        opacity: 0,
        height: '0'
      })),
      transition('visible => hidden', [
        animate('500ms ease-out')
      ]),
      transition('hidden => visible', [
        animate('500ms ease-in')
      ])
    ])
  ]
})
export class MainLayoutComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/json/animation.json',
  };

  styles: Partial<CSSStyleDeclaration> = {
    maxWidth: '500px',
    margin: '0 auto',
  };

  animationCreated(animationItem: AnimationItem): void {
    console.log(animationItem);
  }

  @ViewChild(GlobalSearchComponent) globalSearchComponent?: GlobalSearchComponent;

  public grades: any = [];
  public screenWidth: any;

  isLoggedIn: boolean;
  showGlobalSearch: boolean = false;
  showGlobalSearchMobile: boolean = false;
  isTopHeaderVisible = true;
  topHeaderElement: HTMLElement | null = null;

  constructor(public router: Router, private windowSrv: WindowService, private data: DataService,
    @Inject(LOCALE_ID) public locale: string, private authService: AuthService, public modalService: NgbModal) {
    this.isLoggedIn = !!localStorage.getItem('__dp-user');
    // console.log(this.isLoggedIn);

  }

  ngOnInit(): void {
    if (this.windowSrv.isBrowser) this.screenWidth = this.windowSrv.nativeWindow.innerWidth;
    this.grades = this.data.grades;

    if (this.screenWidth > 1024) {
      this.topHeaderElement = document.querySelector('.top-header');
    }else{
      this.topHeaderElement = document.querySelector('.navbar-mob');
    }


    // store timestamps for each language
    // const lastVisitTimestamp = localStorage.getItem(`lastVisitTimestamp_${this.locale}`);

    // if (!lastVisitTimestamp || this.shouldClearFlag(+lastVisitTimestamp)) {
    //   // It's the first visit or more than 2 hours have passed since the last visit
    //   // this.openModalAlGreet();

    //   // Set the timestamp for the current visit
    //   localStorage.setItem(`lastVisitTimestamp_${this.locale}`, Date.now().toString());
    // }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {

    if (this.topHeaderElement) {
      const topHeaderHeight = this.topHeaderElement.clientHeight;
      if (window.pageYOffset > topHeaderHeight) {
        this.isTopHeaderVisible = false;
      } else {
        this.isTopHeaderVisible = true;
      }
    }

  }

  shouldClearFlag(lastVisitTimestamp: number): boolean {
    // Check if 2 hours (7200000 milliseconds) have passed since the last visit
    const twoHoursInMilliseconds = 2 * 60 * 60 * 1000;
    return Date.now() - lastVisitTimestamp > twoHoursInMilliseconds;
  }

  changeLang(lang: string) {
    // console.log(lang)

    if (lang == 'si') {
      this.router.navigate(['']);
    } else {
      this.router.navigate([lang]);
    }

  }

  getCurrentLang() {
    return this.locale;
  }

  gotohome() {
    this.router.navigate(['/']);
  }

  logout() {
    const modelRef = this.modalService.open(LogoutConfirmComponent, { centered: true, backdrop: false });
    modelRef.componentInstance.modalRef = modelRef;
  }

  openModalAlGreet() {
    const modalRef = this.modalService.open(AlGreetModalContentComponent, { centered: true, backdrop: 'static' });
  }


  displayGlobalSearch() {
    this.showGlobalSearch = true
    setTimeout(() => {
      this.globalSearchComponent?.focusInput();
    }, 3000);
  }

  hideGlobalSearch() {
    this.showGlobalSearch = false
  }

  displayGlobalSearchMobile() {
    this.showGlobalSearchMobile = true
  }

  hideGlobalSearchMobile() {
    this.showGlobalSearchMobile = false
  }
}